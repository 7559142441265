import React, { useContext } from "react";
import styled from "styled-components";
import { Link } from "gatsby";
import { withPrismicUnpublishedPreview } from "gatsby-plugin-prismic-previews";

// Context
// import { ColorScheme } from "../components/context/color-scheme";

// Pages
import Index from "./index";

const Page = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-content: center;

  position: relative;

  padding: 44px 50px 50px 50px;

  height: 100vh;

  transition: 1500ms all ease;
  text-align: center;

  & h1 {
    font-size: 30px;
    line-height: 32px;
    letter-spacing: 0.2px;
  }

  & p {
    margin: 5px 0 0 0;
  }

  @media (max-width: 740px) {
    padding: 20px;

    & h1 {
      font-size: 20px;
      line-height: 22px;
    }
  }
`;

const NotFoundPage = () => {
  // const [activeColorScheme, setActiveColorScheme] = useContext(ColorScheme);

  return (
    <Page
    // bgColor={activeColorScheme.background}
    // color={activeColorScheme.color}
    >
      <div className="container">
        <h1>Page not found!</h1>
        <p>
          <Link to={`/`}>Back to homepage</Link>
        </p>
      </div>
    </Page>
  );
};

export default withPrismicUnpublishedPreview(NotFoundPage);
